<template>
    <div>
        <div class="container pt">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumbs-wrapper">
                        <ul class="breadcrumbs">
                            <li>
                                <a href="/">{{ $t('shared.breadcrumbs.home') }}</a>
                            </li>
                            <li>{{ $t('appname') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h1 data-qa="headline">{{ $t('index.headline') }}</h1>
                </div>
                <div class="col-12 mb" v-if="!isSupportedLocale">
                    <div class="crefo-ui-alert info">
                        <span class="alert-close" onclick="this.parentElement.style.display='none'"></span>
                        The <i>Creditreform Magazine</i> is available in German only.
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-brand-blue">
            <div class="container pt">
                <div class="row">
                    <div class="col-6 text-white">
                        <p class="text-bold">{{ $t('version') }} {{ currentEpaper.version }}</p>
                        <h2 class="text-white">{{ currentEpaper.title }}</h2>
                        <p class="mb">{{ currentEpaper.subline }}</p>
                        <a
                            data-qa="download-latest-link"
                            class="btn btn-default btn-icon-download btn-white mb"
                            :href="getDownloadUrl(currentEpaper)"
                            :download="getDownloadFilename(currentEpaper)"
                        >
                            <span>{{ $t('index.teaser.button.downloadnow') }}</span>
                        </a>
                        <router-link
                            v-bind="{'data-qa': 'read-latest-link'}"
                            class="btn btn-default btn-icon-next btn-white mb"
                            :to="getEpaperUrl(currentEpaper)"
                        >
                            <span>{{ $t('index.teaser.button.readnow') }}</span>
                        </router-link>
                    </div>
                    <div class="col-6 text-center">
                        <img
                            class="current-epaper-thumb box-shadow-xy mb"
                            :src="getThumbnailUrl(currentEpaper.filename)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-brand-light-grey" id="archiv">
            <div class="container pt">
                <div class="row">
                    <div class="col-12">
                        <h2>{{ $t('index.archive.headline') }}</h2>
                    </div>
                </div>
                <div
                    class="row mb"
                    v-for="row in rowCount"
                    :key="row"
                >
                    <div
                        class="col-12 col-md-3 mb"
                        v-for="(epaper, index) in itemCountInRow(row)"
                        :key="index"
                    >
                        <router-link
                            class="archive-link"
                            :to="getEpaperUrl(epaper)"
                        >
                            <div class="archive-thumb-wrapper box-shadow-xy">
                                <img
                                    class="archive-epaper-thumb"
                                    :src="getThumbnailUrl(epaper.filename)"
                                />
                                <div class="archive-epaper-thumb--overlay">
                                    <p class="text-white text-bold">
                                        {{ epaper.subline }}
                                    </p>
                                </div>
                            </div>
                        </router-link>
                        <p>
                            <span class="text-bold">{{ $t('version') }} {{ epaper.version }}:</span>
                            <span style="position: absolute;right: 15px;">
                                <router-link
                                    v-bind="{'data-qa': 'read-archive-link', 'title': $t('index.teaser.button.readnow')}"
                                    class="link-icon-next mb"
                                    :to="getEpaperUrl(epaper)"
                                >
                                    <i class="crefo-ui-icon icon-eye-open" aria-hidden="true"></i>
                                </router-link>
                                <a
                                    style="margin-left: 15px;"
                                    :title="$t('index.teaser.button.downloadnow')"
                                    data-qa="download-archive-link mb"
                                    :href="getDownloadUrl(epaper)"
                                    :download="getDownloadFilename(epaper)"
                                >
                                    <i class="crefo-brand-icon icon-crefo-file" aria-hidden="true"></i>
                                </a>
                            </span>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'

    type Epaper = { version: string, filename: string, title: string, subline: string }

    export default defineComponent({
        name: 'IndexView',
        data () {
            return {
                itemsPerRow: 4,
                /* Add new items to the bottom! */
                epapers: [
                    {
                        version: '07 - 2020',
                        filename: '2020-07',
                        title: 'Rette sich, wer kann',
                        subline: 'Wann kommt die Insolvenzwelle und wie schützen sich Unternehmer vor den wirtschaftlichen Folgen der Coronakrise?'
                    },
                    {
                        version: '08 - 2020',
                        filename: '2020-08',
                        title: 'Die Kurve kriegen',
                        subline: 'Wie kreative Unternehmen ihr Geschäft an die Krise anpassen - und wie sich die Fähigkeit trainieren lässt.'
                    },
                    {
                        version: '09 - 2020',
                        filename: '2020-09',
                        title: 'Schließt die Lücke',
                        subline: 'Der Manager geht, das Wissen bleibt. Wie Interim-Manager als Führungskräfte auf Zeit Know-How in Unternehmen bringen.'
                    },
                    {
                        version: '10 - 2020',
                        filename: '2020-10',
                        title: 'Gemeinsam',
                        subline: 'Keine Zeit für Einzelkämpfer. Wie Unternehmernetzwerke ihre Mitglieder stützen und ihnen durch die Krise helfen.'
                    },
                    {
                        version: '11 - 2020',
                        filename: '2020-11',
                        title: 'Wie immun ist Ihre Bilanz?',
                        subline: 'Warum Banken von Unternehmen in Corona-Zeiten umso mehr Tranzparenz erwarten.'
                    },
                    {
                        version: '12 - 2020',
                        filename: '2020-12',
                        title: 'Das nächste Level',
                        subline: 'Wie Unternehmer im Direktvertrieb über Webshops und Plattformen ihr Geschäft anschieben.'
                    },
                    {
                        version: '01 - 2021',
                        filename: '2021-01',
                        title: 'Das neue Jahr bringt ...',
                        subline: 'Die große Bankenumfrage: Die Volkswirte der Institute und Verbände blicken verhalten optimistisch auf 2021.'
                    },
                    {
                        version: '02 - 2021',
                        filename: '2021-02',
                        title: 'Die perfekte Mischung',
                        subline: 'Die einen wollen schnell wieder ins Büro, die anderen für immer im Homeoffice bleiben. Wie sieht die Zukunft der Büroarbeit aus?'
                    },
                    {
                        version: '03 - 2021',
                        filename: '2021-03',
                        title: 'Keine Sorge',
                        subline: 'Nein, diese Ausgabe ist nicht zum Einschlafen langweilig. Im Gegenteil: Lesen Sie, wie Sie Ängste in Krisenzeiten in den Griff bekommen.'
                    },
                    {
                        version: '04 - 2021',
                        filename: '2021-04',
                        title: 'Zeit für eine Zäsur',
                        subline: 'Chancen und Risiken einer Restrukturierung: Warum ein harter Einschnitt oft besser ist als ein Ende auf Raten.'
                    },
                    {
                        version: '05 - 2021',
                        filename: '2021-05',
                        title: 'Der Preis der Krise',
                        subline: 'Die wirtschaftliche Unsicherheit aufgrund der Pandemie schlägt auch auf die Unternehmensbewertung durch. Wie Verkäufer dennoch realistische Preise erzielen.'
                    },
                    {
                        version: '06 - 2021',
                        filename: '2021-06',
                        title: 'Das geht so schnell nicht weg',
                        subline: 'Welche Spuren die Pandemie hinterlässt und wie Unternehmen sich für die neue Wirtschaftswelt rüsten.'
                    },
                    {
                        version: '07 - 2021',
                        filename: '2021-07',
                        title: 'Wo ist der Schatz im Förderdschungel?',
                        subline: 'EU und Bund stellen Milliarden für Umwelt- und Klimaschutz bereit. Wie Unternehmen aus Green Deal und Co. das Beste für sich herausholen.'
                    },
                    {
                        version: '08 - 2021',
                        filename: '2021-08',
                        title: 'Wer hat das beste Paket?',
                        subline: 'Zur Bundestagswahl buhlen die Parteien auch um die Wirtschaft. Die Wahlprogramme im Mittelstandscheck: Was können Unternehmen von wem erwarten?'
                    },
                    {
                        version: '09 - 2021',
                        filename: '2021-09',
                        title: 'Der Export schließt die Lücke',
                        subline: 'Der Außenhandel ist das wichtigste Zugpferd für den Aufschwung nach der Corona-Krise. Doch hier und da stockt es noch im Exportgeschäft.'
                    },
                    {
                        version: '10 - 2021',
                        filename: '2021-10',
                        title: 'Lieblingskollege Computer',
                        subline: 'Künstliche Intelligenz soll den Büroalltag revolutionieren – und Mitarbeitern lästige Routinejobs abnehmen. Warum sind Unternehmen dennoch skeptisch?'
                    },
                    {
                        version: '11 - 2021',
                        filename: '2021-11',
                        title: 'KMU am Scheideweg',
                        subline: 'Hunderttausende Unternehmen müssen in den nächsten Jahren eine Nachfolgelösung finden. Welche Optionen sie haben und was sie vorbereiten können.'
                    },
                    {
                        version: '12 - 2021',
                        filename: '2021-12',
                        title: 'Was die Leere lehrt',
                        subline: 'Unterbrochene Lieferketten bremsen den Aufschwung. Wie Mittelständler ihr Beschaffungsmanagement neu organisieren.'
                    },
                    {
                        version: '01 - 2022',
                        filename: '2022-01',
                        title: 'Aufschwung ausgebremst',
                        subline: 'Gut gefüllte Auftragsbücher treffen auf Omikron und Inflation. Das bremst den Aufschwung, hält ihn aber nicht auf. Warum Deutschlands Banken ihre Geschäftskunden für gut gerüstet sehen.'
                    },
                    {
                        version: '02 - 2022',
                        filename: '2022-02',
                        title: 'Zeitenwende',
                        subline: 'Der Krieg in der Ukraine erschüttert die Welt – und die Wirtschaft. Jetzt erst recht müssen Unternehmen ihre Strukturen hinterfragen: von der Lieferkette über Mobilität bis zur Cyberabwehr.'
                    },
                    {
                        version: '03 - 2022',
                        filename: '2022-03',
                        title: 'Stoppt die Kettenreaktion',
                        subline: 'Preise unter Druck, Lieferketten angespannt, Ausblick verhalten. Lässt sich die Insolvenzwelle noch brechen?'
                    },
                    {
                        version: '04 - 2022',
                        filename: '2022-04',
                        title: 'Grünes Bürokratiemonster',
                        subline: 'Auf knapp 80 Seiten gibt die EU künftig vor, welche Unternehmen wie über ihre Nachhaltigkeit berichten müssen. Ein immenser Aufwand für den Mittelstand – der sich dennoch darauf vorbereiten muss.'
                    },
                    {
                        version: '05 - 2022',
                        filename: '2022-05',
                        title: 'Auf Sparflamme',
                        subline: 'Der Mittelstand arbeitet fieberhaft daran, seinen Gasverbrauch zu reduzieren und sein Geschäft zu sichern. Doch er stößt dabei an Grenzen. Noch.'
                    },
                    {
                        version: '06 - 2022',
                        filename: '2022-06',
                        title: 'Der Letzte macht das Licht aus',
                        subline: 'Hunderttausende Unternehmen stehen in den kommenden Jahren zur Übergabe an. Doch das Interesse schwindet. Jedem dritten droht mangels Nachfolger das Aus.'
                    },
                    {
                        version: '01 - 2023',
                        filename: '2023-01',
                        title: 'Rezession oder Rezessiönchen?',
                        subline: 'Inflation, steigende Zinsen, geopolitische Unsicherheit. Viele Probleme sind mit ins neue Jahr gewandert. Sie werden bestimmen, wie schlimm der Abschwung wird.'
                    },
                    {
                        version: '02 - 2023',
                        filename: '2023-02',
                        title: 'Grüne Geschäfte',
                        subline: 'Keine Klimaneutralität ohne die Wirtschaft: Über einen gewaltigen Kraftakt und seine Chancen.'
                    },
                    {
                        version: '03 - 2023',
                        filename: '2023-03',
                        title: 'Wenn zwei sich streiten ...',
                        subline: '... verliert der Dritte. Mit welchen Strategien Mittelständler im Auslandsgeschäft stark bleiben.'
                    },
                    {
                        version: '04 - 2023',
                        filename: '2023-04',
                        title: 'Ist da noch jemand?',
                        subline: 'Fehlende Fachkräfte nehmen Unternehmen den Spielraum. Erfolgreich bleibt, wer Personalprobleme clever lösen kann.'
                    },
                    {
                        version: '01 - 2024',
                        filename: '2024-01',
                        title: 'Trübe Stimmung?',
                        subline: 'Die Konjunkturprognosen der Top-Ökonomen deutscher Banken sind verhaltener als die der Politik. Oder sind sie schlicht realistischer? Was die Institute erwarten – und was das für den Mittelstand bedeutet.'
                    },
                    {
                        version: '02 - 2024',
                        filename: '2024-02',
                        title: 'Was kann KI...',
                        subline: '... und was noch nicht? Wie Künstliche Intelligenz Unternehmen beflügelt.'
                    },
                    {
                        version: '03 - 2024',
                        filename: '2024-03',
                        title: 'Keine Wirtschaft ohne Gesellschaft',
                        subline: 'Ökologische Nachhaltigkeit ist etabliert. Warum es für Unternehmen jetzt noch wichtiger wird, sozial nachhaltig zu arbeiten.'
                    },
                    {
                        version: '04 - 2024',
                        filename: '2024-04',
                        title: 'Das Master-Mindset',
                        subline: 'Unternehmen, Teams und Führungskräfte müssen sich ändern, um die Transformation zu meistern. Aber wie?'
                    },
                    {
                        version: '01 - 2025',
                        filename: '2025-01',
                        title: 'Deutschland guckt in die Röhre',
                        subline: 'Die Wirtschaft stagniert weiter. Für 2025 erwarten Ökonomen bestenfalls ein Mini-Wachstum. Aber was genau bremst uns aus? Zeit für einen realistischen Blick auf Probleme und Chancen.'
                    }
                ] as Epaper[]
            }
        },
        computed: {
            isSupportedLocale () {
                return [ 'de_DE', 'de_AT', 'de_CH' ].includes(this.$i18n.locale)
            },
            currentEpaper: function() {
                return this.epapers[0]
            },
            rowCount: function() {
                return Math.ceil(this.epapers.length / this.itemsPerRow)
            },
            archivedEpapers: function() {
                return this.epapers.reverse()
            }
        },
        methods: {
            getDownloadFilename (epaper: Epaper) {
                return `Creditreform-Magazin_-_${epaper.filename}.pdf`
            },
            getDownloadUrl (epaper: Epaper) {
                return `/portal/creditreform-magazin/assets/e-papers/${epaper.filename}.pdf`
            },
            getEpaperUrl (epaper: Epaper) {
                return `/portal/creditreform-magazin/e-paper?file=${epaper.filename}.pdf&name=${epaper.version}`
            },
            getThumbnailUrl (filename: string) {
                if (import.meta.env.MODE !== 'production' &&
                    import.meta.env.MODE !== 'test' &&
                    typeof console !== 'undefined'
                ) {
                    return new URL(`../assets/img/${filename}-thumb.png`, import.meta.url).href
                } else {
                    return `/portal/creditreform-magazin/assets/e-papers/${filename}-thumb.png`
                }
            },
            itemCountInRow (index: number) {
                return this.archivedEpapers.slice((index - 1) * this.itemsPerRow, index * this.itemsPerRow)
            }
        }
    })
</script>

<style scoped lang="less">
    .btn.btn-icon-download {
        margin-right: 15px;

        span {
           margin-right: 15px;
        }

        span::after {
            font-family: crefo-brand-icons!important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            content: "\e909";
            font-size: 21px;
            padding-left: 5px;
        }
    }

    .btn.btn-default {
        &.btn-white {
            color: #009ee2;
            background-color: #ffffff;
            margin-top: 60px;

            &:hover {
                color: #004884;
            }
        }

        &.btn-icon-next {
            @media only screen and (max-width: 991px) {
                margin-top: 15px;
            }
        }
    }

    .current-epaper-thumb {
        max-height: 400px;
        width: auto;
        margin-top: -60px;
    }

    @media only screen and (max-width: 991px) {
        .current-epaper-thumb {
            margin-top: 30px;
        }
    }

    .text-center {
        text-align: center;
    }

    .archive-epaper-thumb {
        width: 100%;
        height: auto;
    }

    .archive-epaper-thumb--overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 72, 132, .95);
        opacity: 0;
        transition: opacity 0.15s ease-in-out;

        p {
            padding: 30px;
            overflow-wrap: break-word;

            @media only screen and (max-width: 1199px) {
                padding: 20px;
                font-weight: 600;
                font-size: 12px;
                line-height: 1.2;
                overflow: hidden;
            }
            @media only screen and (max-width: 991px) {
                padding: 15px;
                font-weight: normal;
                font-size: 10px;
                line-height: normal;
                overflow: hidden;
                letter-spacing: 0.2px;
            }
            @media only screen and (max-width: 767px) {
                padding: 30px;
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.5px;
            }
        }
    }

    .archive-thumb-wrapper {
        margin-bottom: 15px;
        position: relative;

        &:hover {
            .archive-epaper-thumb--overlay {
                opacity: 1;
            }
        }
    }
</style>
